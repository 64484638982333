import './App.less';
import {HelmetProvider} from "react-helmet-async";
import {ConfigProvider} from "antd";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {HomePage} from "./features/home/HomePage";
import Page1688 from "./features/1688/Page1688";
import Search1688Content from "./features/1688/search/Search1688Content";
import Products1688 from "./features/1688/products/Products1688";
import ProductRus from './features/rus/products/ProductRus';

import PageRUS from "./features/rus/PageRUS";
import SearchRusContent from "./features/rus/search/SearchRusContent";


const App = (props) => {

    return (
        <HelmetProvider>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: "'Exo 2', Roboto, 'Open Sans', arial, sans-serif",
                        fontSize: 13
                    },
                    components: {
                        Layout: {
                            headerBg: '#F2F5F9',
                            colorBgLayout: '#fff',
                            footerBg: '#F2F5F9',
                            siderBg: '#fff',
                            headerHeight: '5',
                            headerPadding: '20px 50px',
                        },
                        Menu: {
                            itemBg: '#F2F5F9',
                            activeBarHeight: 4
                        }
                    }
                }}
            >

                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<HomePage/>}>
                            <Route element={<Navigate to="1688" replace/>}/>
                            <Route path="1688" element={<Page1688/>}>
                                <Route index element={<Navigate to="search" replace/>}/>
                                <Route path="search" element={<Search1688Content/>}/>
                                {<Route path="products/:productId" element={<Products1688/>}/>}
                            </Route>
                            <Route index element={<Navigate to="rus" replace/>}/>
                            <Route path="rus" element={<PageRUS/>}>
                                <Route index element={<Navigate to="search" replace/>}/>
                                <Route path="search" element={<SearchRusContent/>}/>
                                {<Route path="products/:productId" element={<ProductRus/>}/>}
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ConfigProvider>
        </HelmetProvider>
    );
}

export default App;
