import {configureStore} from "@reduxjs/toolkit";
import search1688Reducer from './features/1688/search/search1688Slice';
import product1688Reducer from "./features/1688/products/product1688Slice";

import searchRusReducer from './features/rus/search/searchRusSlice';
import productRusReducer from './features/rus/products/productRusSlice';

const store = configureStore({
    reducer: {
        search1688: search1688Reducer,
        product1688: product1688Reducer,
        searchRus: searchRusReducer,
        productRus: productRusReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

store.subscribe(() => {
    // @ts-ignore

})

export default store;

