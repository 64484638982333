import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import '../../../constants/enums';
import {product1688, productRus, qa} from "../../../services/apiService";
import {search1688Slice} from "../search/search1688Slice";

export const product1688Slice = createSlice({
    name: 'product',
    initialState: {
        loading: false,
        productId: undefined,
        product: {},
        currentImage: undefined,
        currentProps:{},
        qa:{},
    },
    reducers: {
        setCurrentImage: (state, {payload}) => {
            state.currentImage =payload;
        },
        setCurrentProp: (state, {payload}) => {
            const {pid, vid} = payload;
            state.currentProps[pid] = vid;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(productsRequestAsync.pending, (state, action) => {
                const {productId} = action.meta.arg;
                state.loading = true;
                state.productId = productId;
            })
            .addCase(productsRequestAsync.fulfilled, (state, action) => {
                const {mainImgs = [], skuProps = []} = action.payload;
                let currentProps = {};
                skuProps.forEach((prop, index)=> {
                    const {pid, values = []} = prop;
                    if(index !== skuProps.length -1 ) {
                        if (values.length > 0) {
                            currentProps[pid] = values[0].vid;
                        }
                    }
                })
                state.currentProps = currentProps;
                state.loading = false;
                state.product = action.payload;
                state.currentImage = mainImgs.length > 0 ? mainImgs[0] : undefined;

            })
            .addCase(productsRequestAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(productRequestAsyncRus.pending, (state, action) => {
                const {productId} = action.meta.arg;
                state.loading = true;
                state.productId = productId;
            })
            .addCase(productRequestAsyncRus.fulfilled, (state, action) => {
                const {mainImgs = [], skuProps = []} = action.payload;
                let currentProps = {};
                /* skuProps.forEach((prop, index)=> {
                    const {pid, values = []} = prop;
                    if(index !== skuProps.length -1 ) {
                        if (values.length > 0) {
                            currentProps[pid] = values[0].vid;
                        }
                    }
                }) */
                state.currentProps = currentProps;
                state.loading = false;
                state.product = action.payload;
                state.currentImage = mainImgs.length > 0 ? mainImgs[0] : undefined;

            })
            .addCase(productRequestAsyncRus.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(domainQa.fulfilled, (state, action) => {
                state.qa = action.payload;
            })
    }
})

export const {setCurrentImage, setCurrentProp} = product1688Slice.actions;

export const productsRequestAsync = createAsyncThunk('product/productsRequestAsync',
    ({productId}) => {
    return product1688(productId);
})

export const productRequestAsyncRus = createAsyncThunk('product/productRequestAsync',
    ({productId}) => {
    return productRus(productId);
})

export const domainQa = createAsyncThunk('domain/qa',
    ({domain}) => {
    return qa(domain);
})

export default product1688Slice.reducer;
