import React, {useState, useRef, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {productRequestAsyncRus, domainQa} from "../../1688/products/product1688Slice";
import ProductPropertiesContainer from "../../1688/products/ProductPropertiesContainer";
import ProductDetailsContainer from "../../1688/products/ProductDetailsDescription";
import ProductImageContainer from "../../1688/products/ProductImageContainer";
import DomainQAContainer from "./DomainQAContainer"
import {Skeleton, Layout, Flex, Divider} from "antd";

import './ProductsRus.less';

const { Sider, Content } = Layout;

const ProductRus = () => {
    const {productId} = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const product = useSelector(state => state.product1688.product)
    
    const loading = useSelector(state => state.product1688.loading)
    const {title=''} = product; 

    const getQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    const pageDomain = getQueryParam('domain');

    useEffect(()=>{
        dispatch(productRequestAsyncRus({productId}));
    },[dispatch, productId])

    useEffect(()=>{
        dispatch(domainQa({domain : pageDomain}));
    },[dispatch, pageDomain])

    if(!loading) {
        return (
            <Layout hasSider className='product-page'>
                <Content className='product-content'>
                    <Flex  className='product-info'>
                        <ProductImageContainer product={product}/>
                        <ProductPropertiesContainer product={product}/>
                    </Flex>
                    <Divider />
                    <ProductDetailsContainer product={product}/>
                </Content>
                <Sider className='product-sider' width='400'>
                    <DomainQAContainer domain={pageDomain}/>
                </Sider>
            </Layout>
        )
    } else {
        return (
            <Skeleton active/>
        )
    }
}

export default ProductRus;