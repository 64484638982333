import React from 'react'


const HomeFooter = () => {
    return(
        <div>FOOTER</div>
    )
}

export default HomeFooter;
