import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentImage, setCurrentProp} from "./product1688Slice";
import {Flex, Typography, InputNumber, Descriptions, Divider} from "antd";
import { Link } from 'react-router-dom';



const SkuProps = ({product ={}}) => {
    const dispatch = useDispatch();
    const currentImage = useSelector(state => state.product1688.currentImage);
    const currentProps = useSelector(state => state.product1688.currentProps);
    const {skuPropsN =[], skus = [], currency='CNY', description} = product;

    var skuProps = skuPropsN;
    if (skuPropsN == null) {
        skuProps = []
    }

    const propRenderer = (prop) => {
        const {prop_name, pid, values = []} = prop;
        const currentProp = currentProps[pid];

        const setCurrentData = (imageUrl, pid, vid) => {
            if (imageUrl)
                dispatch(setCurrentImage(imageUrl));
            dispatch(setCurrentProp({pid : pid, vid : vid}));
        }

        return(
            <div className='sku-prop' key={pid}>
                <div className='title'>{prop_name}</div>
                <div className='img-data-container'>
                    {values.map((val, index) => {
                        const {name, vid, imageUrl} = val;
                        const isActive = currentProp === vid;

                        return(
                            <div className={isActive ? 'img-data active' : 'img-data'} key={vid}
                                 onClick={()=> setCurrentData(imageUrl, pid, vid)}>
                                { imageUrl && <img src={imageUrl} alt=''
                                     onClick={()=>{
                                         setCurrentData(imageUrl, pid, vid);
                                     }}
                                /> }
                                <span>{name}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const currentPropValue = () => {
        return Object.entries(currentProps).map(( value) => {
            return value[0] + ':' + value[1];
        }).join(';');
    }

    const skuByProp = (propsIds) => {
        return skus.find(sku=> sku.props_ids === propsIds );
    }

    const amnPropRenderer = (prop) => {
        const {prop_name, pid, values = []} = prop;
        const propValues = currentPropValue();
        return(
            <div className='sku-prop' key={pid}>
                <div className='title'>{prop_name}</div>
                <div className='prop-data-container'>
                    {values.map((val,idex)=>{
                        const {name, vid, imageUrl} = val;
                        const propsIds =
                            propValues ? propValues + ';' + pid + ':' + vid
                            : pid + ':' + vid;
                        const sku = skuByProp(propsIds);
                        if(sku) {
                            const {skuid, specid, sale_price, original_price, stock} = sku;
                            const price = sale_price ? sale_price : original_price;
                            return (
                                <div className='prop-data' key={vid}>
                                    <div className='prop-data-img'>
                                        {imageUrl && <img src={imageUrl} alt=''/>}
                                        <span>{name}</span>
                                    </div>
                                    <div className='prop-data-price'>{price.toLocaleString('zh-CN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency: currency
                                    })}</div>
                                    <div className={`prop-data-stock ${stock > 0 ? 'in-stock' : ''}`}>
                                        {stock > 0 ? 'в наличии' : 'нет на складе'}
                                    </div>
                                    <div className='prop-data-amn'>
                                        <InputNumber size="small" min={0} max={100000} defaultValue={0}/>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }

    return (
        <Flex>
            {
                skuProps.map((prop, index) => {
                    if(index !== skuProps.length-1)
                        return propRenderer(prop);
                    else
                        return amnPropRenderer(prop);
                })
            }
        </Flex>
    )

}

const ProductPropertiesContainer = ({product = {}}) => {
    const {title='', skuPriceRange = {}, priceInfo = {}, currency = 'CNY',
        productUrl, originalTitle, description, domain} = product;

    var skuPriceRangeN = skuPriceRange

    if (skuPriceRangeN == null) {
        skuPriceRangeN = {};
    }

    const priceRangeRenderer = () => {
        const {sku_param = [], begin_num = 1, mixParam = {}} = skuPriceRangeN;
        const {price=0} = priceInfo;
        if(sku_param.length > 0 ) {
            return(
                sku_param.map((param, index) => {
                    const {price = 0, beginAmount = 0} = param;
                    return (
                        <Flex vertical className='data right' key={index}>
                            <div className='price'>{price.toLocaleString('zh-CN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: currency
                            })}</div>
                            <div>{beginAmount}</div>
                        </Flex>
                    )
                })
            )
        }
        else {
            return(
                <Flex vertical className='data right'>
                    <div className='price'>{price.toLocaleString('zh-CN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'RUB'
                    })}</div>
                    <div>{begin_num}</div>
                </Flex>
        )}
    }

    return(
        <Flex vertical className='product-properties-container'>
            <Typography.Title>{title}</Typography.Title>
            
            <Divider />

            <div className='price-range'>
                <div className='title'>
                    <div>Цена</div>
                    <div>Мин.кол-во</div>
                </div>
                {priceRangeRenderer()}
            </div>

            <Divider />
            
            <Typography.Paragraph>{description}</Typography.Paragraph>

            <SkuProps product={product} />
            <Link to={productUrl}>Открыть страницу товара на {domain}</Link>
        </Flex>
    )
}

export default ProductPropertiesContainer;
