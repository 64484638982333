import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Image, Pagination, Skeleton, Flex, Divider} from "antd";
import ProductViewer from "./ProductViewer";
import './SearchResultContent.less'
import {searchProductsAsync} from "./search1688Slice";

const SearchProductResultContent = () => {
    const dispatch = useDispatch();
    const productsResult = useSelector(state => state.search1688.productsResult)
    const loading = useSelector(state => state.search1688.loading)
    const {sort} = productsResult;

    const productsRenderer = () => {
        const {products} = productsResult;
        return products.map(product => {
            return (
                <Flex vertical>
                    <ProductViewer product={product}/>
                    <Divider />
                </Flex>
            )
        });
    }

    const paginationRenderer = () => {
        const {pagination: { total = 0, pageSize = 20, current= 1}} = productsResult;
        if(total > 0) {
            return <Pagination
                // hideOnSinglePage={true}
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                pageSize={pageSize}
                current={current}
                onChange={(page, pageSize) => {
                    const {keywords, originalKeywords, pagination} = productsResult;
                    const newPagination = {
                        ...pagination,
                        current: page,
                    }

                    dispatch(searchProductsAsync({keywords, originalKeywords, pagination: newPagination, sort}))
                }}
            />
         }
    }


    const contextRenderer = () => {
        if (!loading) {
            return (
                <Flex vertical>
                    <div className='f-products-content'>{productsRenderer()}</div>
                    <div className='products-content-footer'>
                        {paginationRenderer()}
                    </div>
                </Flex>
            )
        } else {
            return (
                <Skeleton active/>
            )
        }
    }

    return(
        <div className='search-result-content'>
            {contextRenderer()}
        </div>
    )
}

export default SearchProductResultContent;
