import React from 'react'
import './ProductViewer.less'


const ProductViewer = ({product = {}}) => {
    const {itemId, title, originalTitle,
        img, price = 0, productUrl,
        quantityBegin, quantityPrices = [],
        shopInfo = {},
        deliveryInfo = {}, saleInfo = {},
    } = product;
    const fabricusUrl = `/1688/products/${itemId}`;

    const priceRenderer = () => {
        return (
            <div className='product-price-container'>
                <div className='product-price'>{price.toLocaleString('zh-CN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: "CNY"
                    })}
                </div>
                <p className="quantity-title">Мин. кол-во: {quantityBegin}</p>
                <div className="price-range-container">
                    {quantityPrices.map((priceRange, index) => {
                        let {price = 0, end_num, begin_num} = priceRange;
                        price = price === null ? 0 : price;

                        return (<div key={index} className="price-range">
                            <p>{end_num ? `${begin_num} - ${end_num}` : `> ${begin_num}`}</p>
                            <p>{price.toLocaleString('zh-CN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: "CNY"
                            })}</p>
                        </div>)
                    })}
                </div>
            </div>
        )
    }

    const descriptionRenderer = () => {
        const {companyName='', shopRepurchaseRate, bizType,
            soreInfo = {}} = shopInfo;
        const {gmv30days='', saleQuantity = 0, ordersCount = 0} = saleInfo;
        const {weight, suttleWeight, areaFrom = []} = deliveryInfo;

        return(
            <div className='product-description-wrapper'>
                <div className='product-caption'>
                    <a href={fabricusUrl} target='_blank' rel="noreferrer">{title}</a>
                    <div>
                        <a className='original' href={productUrl} target='_blank' rel="noreferrer">{originalTitle} -&gt; 1688.com</a>
                    </div>
                </div>
                <div className='product-detail-container'>
                    <div className='product-detail'>
                        <h4>Продавец</h4>
                        <div className='product-detail-context'>
                            <p>{companyName}</p>
                            <p>Повторные заказы: {shopRepurchaseRate}</p>
                            <p>{bizType}</p>
                        </div>
                    </div>
                    <div className='product-detail'>
                        <h4>Доставка</h4>
                        <div className='product-detail-context'>
                            {areaFrom.map((area, index) => (
                                <p key={index} className="price-range">
                                    {area}
                                </p>
                            ))}
                            <p>Вес: {weight}</p>
                        </div>
                    </div>
                    <div className='product-detail'>
                        <h4>Продажи</h4>
                        <div className='product-detail-context'>
                            <p>Последние 30 дн.: {gmv30days}</p>
                            <p>Продано кол-во: {saleQuantity}</p>
                            <p>Заказов: {ordersCount}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div key={itemId} className='product-viewer-wrapper'>
            <div className='product-content'>
                <div className='product-img'>
                    <a href={fabricusUrl} target='_blank' rel="noreferrer"><img src={img} /></a>
                </div>
                {descriptionRenderer()}
                {priceRenderer()}
            </div>
        </div>
    )
}

export default ProductViewer;

