import React, {useState} from 'react'
import { Input, Radio, Flex} from "antd";
import {useDispatch, useSelector} from "react-redux";
import { searchProductsAsync } from "./searchRusSlice";
import { ProductsSortTypes } from "../../../constants/enums";

const { Search } = Input;

const SearchController = () => {
    const dispatch = useDispatch();

    const productsResult = useSelector(state => state.searchRus.productsResult)
    const [productKeywords, setProductKeywords] = useState(productsResult.keywords);
    const [productSort, setProductSort] = useState(productsResult.sort);


    const productSorts = () => {
        return (
            <Radio.Group className='search-controller-sort'
                name="productSort"
                size="small"
                value={productSort}
                onChange={(e) => {
                    setProductSort(e.target.value);
                    if(productKeywords) {
                        dispatch(searchProductsAsync({
                            keywords: productKeywords,
                            pagination: undefined,
                            sort: e.target.value
                        }))
                    }
                }}
            >
                <Radio.Button value={ProductsSortTypes.DEFAULT}>по умолчанию</Radio.Button>
                <Radio.Button value={ProductsSortTypes.SALES}>по популярности</Radio.Button>
                <Radio.Button value={ProductsSortTypes.PRICE_DOWN}>по цене ум.</Radio.Button>
                <Radio.Button value={ProductsSortTypes.PRICE_UP}>по цене возр.</Radio.Button>
            </Radio.Group>
        )
    }

    const productSearchRenderer = () => {

        return(
            <Search className='search-editor-wrapper'
                placeholder="Наберите наименование товара"
                enterButton="Поиск"
                size="large"
                onSearch={(e) => {
                    dispatch(searchProductsAsync({
                        keywords: productKeywords,
                        pagination: undefined,
                        sort: productSort
                    }))}
                }
                value={productKeywords}
                onChange={(e) => {
                    setProductKeywords(e.target.value)
                }}
            />

        )
    }

    return(
        <Flex vertical className='search-controller-wrapper'>
            {productSearchRenderer()}

        </Flex>
    )
}

export default SearchController;
