import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ProductImageContainer from "./ProductImageContainer";

import ProductPropertiesContainer from "./ProductPropertiesContainer";
import ProductDetailsContainer from "./ProductDetailsDescription";
import {productsRequestAsync} from "./product1688Slice";
import {useDispatch, useSelector} from "react-redux";
import {Skeleton, Divider, Flex, Layout } from "antd";

import './Products1688.less';

const { Content } = Layout;



const Products1688 = () => {
    const {productId} = useParams();
    const dispatch = useDispatch();

    const product = useSelector(state => state.product1688.product)
    const loading = useSelector(state => state.product1688.loading)
    const {title=''} = product;

    useEffect(()=>{
        dispatch(productsRequestAsync({productId}));
    },[dispatch, productId])

    if(!loading) {
        return (
            <Layout className='product-page'>
                <Content className='product-content'>
                    <Flex  className='product-info'>
                        <ProductImageContainer product={product}/>
                        <ProductPropertiesContainer product={product}/>
                    </Flex>
                    <Divider />
                    <ProductDetailsContainer product={product}/>
                </Content>
            </Layout>
        )
    } else {
        return (
            <Skeleton active/>
        )
    }
}

export default Products1688;
