import React, {useEffect, useState} from 'react';
import {Layout, Radio, Space} from "antd";
import {Helmet} from 'react-helmet-async';
import './HomePage.less'
import {useDispatch} from "react-redux";
import {Link, Outlet} from "react-router-dom";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
const {Header, Content, Footer, Sider} = Layout;


const initHelmet = {
    isRoot: false,
    description: 'Поиск производителей для селлеров маркетплейсов',
    title: 'Fabrius'
};


export const HomePage = (props) => {
    const [helmet] = useState(initHelmet);
    const [title, setTitle] = useState(helmet.title);

    const dispatch = useDispatch();
    // const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
            setTitle(`${helmet.title} | Поиск товаров для маркетплейса`);
    }, [helmet]);

    return (
        <Layout className="home-page">
            <Helmet>
                <html lang="en"/>
                <title>{title}</title>
                <meta name="description" content={helmet.description}/>
            </Helmet>

            <Header>
                <HomeHeader />
            </Header>
            <Layout className='main-layout'>
                <Content className='main-layout-content' >    
                    <Outlet />
                </Content>
            </Layout>
            <Footer>
                <HomeFooter />
            </Footer>
        </Layout>
    );
};
