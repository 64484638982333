import {useDispatch, useSelector} from "react-redux";
import {setCurrentImage} from "./product1688Slice";
import { Flex, Image } from 'antd';



const ProductImageSlider = ({imgs = [], onClick = (image) => {}}) => {
    return(
        <Image.PreviewGroup preview={false} items={imgs}>
            <Flex vertical>
                {imgs.map((image, index) => (
                    <Image key={index} width={70} src={image} onClick={() => onClick(image)} />
                ))}
            </Flex>            
        </Image.PreviewGroup>
    )
}


const ProductImageContainer = ({product = {}}) => {
    const dispatch = useDispatch();
    const currentImage = useSelector(state => state.product1688.currentImage);
    const {mainImgs : imgs = []} = product;

    return(
        <Flex className='product-img-container'>
            <ProductImageSlider
                imgs={imgs.slice(0, 5)}
                onClick={(img) => {
                    dispatch(setCurrentImage(img))
                }}
            />
            <Image height={450} src={currentImage} />
        </Flex>
    )
}

export default ProductImageContainer;
