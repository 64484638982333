import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Layout, Pagination, Skeleton, Row, Flex, Divider } from "antd";
import ProductViewer from "./ProductViewer";
import {searchProductsAsync} from "./searchRusSlice";

const { Footer, Content } = Layout;

const SearchProductResultContent = () => {
    const dispatch = useDispatch();
    const productsResult = useSelector(state => state.searchRus.productsResult)
    const loading = useSelector(state => state.searchRus.loading)
    const {sort} = productsResult;
    const {products} = productsResult;

    const productsRenderer = () => {
        return (
            <Row gutter={[16, 16]} style={{ height: '100%' }}>
                {products.map(product => (
                    <ProductViewer key={product.id} product={product} />
                ))}
            </Row>
        )
    }

    const paginationRenderer = () => {
        const {pagination: { total = 0, pageSize = 20, current= 1}} = productsResult;
        if(total > 0) {
            return (
                <Pagination
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                    pageSize={pageSize}
                    current={current}
                    onChange={(page, pageSize) => {
                        const {keywords, pagination} = productsResult;
                        const newPagination = {
                            ...pagination,
                            current: page,
                        }

                        dispatch(searchProductsAsync({keywords, pagination: newPagination, sort}))
                    }}
                />
            )
        } else {
            return null;
        }
    }


    const contextRenderer = () => {
        if (!loading) {
            return (
                <Flex vertical className='f-products-content'>
                        {productsRenderer()}
                        <Divider />
                        {paginationRenderer()}
                </Flex>
            )
        } else {
            return (
                <Skeleton active/>
            )
        }
    }

    return(
        <Flex className='search-result-content'>
            {contextRenderer()}
        </Flex>
    )
}

export default SearchProductResultContent;
