import React, { useState } from 'react'
import './HomeHeader.less'
import {useLocation, useNavigate } from "react-router-dom";
import { Menu, Flex, Typography, Divider, Image } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import logo from '../../logo.png';
import { Link } from 'react-router-dom';

const HomeHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const keys = (path) => {
        var segments = path.split('/');
        return segments[2];
    }

    const [current, setCurrent] = useState('rus');

    const onClick = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    const items = [
        {
          label: 'Россия',
          key: 'rus',
        },
        {
            label: 'Китай(1688.com)',
            key: '1688',
            //icon: <MailOutlined />,
        }
    ]

    return(
        <Flex vertical className='home-header'>
            <Link to={`/`}>
                <Flex className='home-header=logo' align='center' >
                    <img src={logo} alt='logo' />
                    <Divider type="vertical" />
                    <Typography.Title italic >ФАБРИУС</Typography.Title>
                </Flex>
            </Link>
            

        </Flex>
    )}

export default HomeHeader;
