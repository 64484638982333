import React from 'react';
import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";

import {Outlet} from "react-router-dom";


const Page1688 = () => {
    return(
        <Layout>
            <Content className='search-layout-content'>
                <Outlet />
            </Content>
        </Layout>
    );
}

export default Page1688;
