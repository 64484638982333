export const SearchTypes = { PRODUCTS: 'products', FACTORIES: 'factories' };
export const ProductsSortTypes = {
    DEFAULT: 'default',
    SALES: 'sales',
    PRICE_UP: 'price_up',
    PRICE_DOWN: 'price_down'
};

export const FactoriesSortTypes = {
    DEFAULT: 'default',
    REPURCHASE_RATE: 'repurchase_rate'
};
