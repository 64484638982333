import React, {useRef} from 'react';
import {Flex} from "antd";


const MyIframeComponent = ({detailUrl = ''}) => {
    const iframeRef = useRef(null);

    const handleIframeLoad = () => { const iframeContent = iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
        const contentHeight = iframeContent.body.scrollHeight;
        const contentWidth = iframeContent.body.scrollWidth;

        iframeRef.current.style.height = `${contentHeight}px`;
        iframeRef.current.style.width = `${contentWidth}px`;
    }

    return (
        <div className='detail'>
            <iframe ref={iframeRef} src={detailUrl} onLoad={handleIframeLoad} title="Your Iframe" />
        </div>
    );
};

const ProductDetailsDescription = ({product = {}}) => {
    const {productProps = [], detailUrl} = product;

    const characteristicsRender = () => {
        return(
            <div className='characteristics'>
                {productProps.map((prop, index) =>
                    Object.entries(prop).map(([key, value]) => {
                        return(
                            <div key={index}>
                                <div className='key'>{key}</div>
                                <div className='value'>{value}</div>
                            </div>
                        )
                    })
                )}
            </div>
        )
    }



    const detailIFrameRendrer = () => {
        if(detailUrl) {
            return (
                <div className='detail'>
                    <iframe
                        src={detailUrl}
                        seamless={true}
                    />
                </div>
            );
        }
    }


    return(
        <Flex vertical className='product-detail-container'>
            {characteristicsRender()}
            {detailIFrameRendrer()}
        </Flex>
    );
}

export default ProductDetailsDescription;
