import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {searchProducts, searchProductsRus} from "../../../services/apiService";
import '../../../constants/enums';
import { ProductsSortTypes, SearchTypes} from "../../../constants/enums";

export const searchRusSlice = createSlice({
    name: 'ruSearch',
    initialState: {
        loading: false,
        searchType: SearchTypes.PRODUCTS,

        productsResult: {
            keywords: "",
            products: [],
            pagination: {},
            sort: ProductsSortTypes.DEFAULT
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchProductsAsync.pending, (state, action) => {
                const {keywords, sort} = action.meta.arg;
                state.productsResult = {
                    ...state.productsResult,
                    keywords,
                    sort,                }
                state.loading = true;
            })
            .addCase(searchProductsAsync.fulfilled, (state, action) => {
                const { content: data = [], pageable: page, totalElements, sort} = action.payload;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.productsResult = {
                    ...state.productsResult,
                    sort,
                    products: data,
                    pagination
                }
                state.loading = false;
            })
            .addCase(searchProductsAsync.rejected, (state, action) => {
                state.loading = false;
            })
    }
})


export const searchProductsAsync = createAsyncThunk('ruSearch/searchProductsAsync',
    ({keywords, pagination, sort}) => {
    return searchProductsRus(keywords, pagination, sort); 
    
    //return {"products": {"content":["p1", "p2"], "pageable" : 1, "totalElements" : 2}};
})


export default searchRusSlice.reducer;
