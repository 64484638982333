import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import '../../../constants/enums';
import {productRus} from "../../../services/apiService";

export const productRusSlice = createSlice({
    name: 'ruProduct',
    initialState: {
        loading: false,
        productId: undefined,
        product: {},
        currentImage: undefined,
        currentProps:{},
    },
    reducers: {
        setCurrentImage: (state, {payload}) => {
            state.currentImage =payload;
        },
        setCurrentProp: (state, {payload}) => {
            const {pid, vid} = payload;
            state.currentProps[pid] = vid;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(productRequestAsync.pending, (state, action) => {
                const {productId} = action.meta.arg;
                state.loading = true;
                state.productId = productId;
            })
            .addCase(productRequestAsync.fulfilled, (state, action) => {
                const {mainImgs = [], skuProps = []} = action.payload;
                /* let currentProps = {};
                skuProps.forEach((prop, index)=> {
                    const {pid, values = []} = prop;
                    if(index !== skuProps.length -1 ) {
                        if (values.length > 0) {
                            currentProps[pid] = values[0].vid;
                        }
                    }
                })
                state.currentProps = currentProps; */
                state.loading = false;
                state.product = action.payload;
                state.currentImage = mainImgs.length > 0 ? mainImgs[0] : undefined;

            })
            .addCase(productRequestAsync.rejected, (state, action) => {
                state.loading = false;
            })
    }
})

export const {setCurrentImage, setCurrentProp} = productRusSlice.actions;

export const productRequestAsync = createAsyncThunk('ruProduct/productRequestAsync',
    ({productId}) => {
    return productRus(productId);
})



export default productRusSlice.reducer;
