import {
    API_BASE_URL, SEARCH_BASE_PATH, SEARCH_BASE_PATH_RUS

} from "../constants";
import axios from 'axios';


const getInstance = (timeout) => {
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + localStorage.getItem(TOKEN)
    };

    return axios.create({
        baseURL: API_BASE_URL,
        timeout: timeout,
        headers: headers,
        withCredentials: false
    });
}

const get = (url, options, timeout = 15000) => {
    const instance = getInstance(timeout);
    //console.log(url);
    return instance.get(url, options)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}


const post = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.post(url, values).then(response => {
        if (response.status !== 200 && response.status !== 201) {
            return Promise.reject(response);
        }
        return response.data;
    });
}

const put = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.put(url, values)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}

const patch = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);
    return instance.patch(url, values);
}

const del = (url, options, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.delete(url, options).then(response => {
        if (response.status !== 200) {
            return Promise.reject(response);
        }
        return response.data;
    });
}


const orderDirectionConverter = (order) => {
    switch (order) {
        case 'descend' : return 'desc';
        case 'ascend' : return 'asc';
        default: return '';
    }
}

const fieldsFilterToParams = (fieldsFilter) => {
    const fieldParams = {};
    Object.keys(fieldsFilter).filter(item => fieldsFilter[item]).forEach((item) => {
        fieldParams[item] = fieldsFilter[item].join(',');
    });
    return fieldParams;
}


/********************************************************
 * SEARCH
 *
 */

export const searchProducts = (keywords, originalKeywords, pagination, sort) => {
    let params = {
        keywords,
        originalKeywords,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    if(sort) {
        params = {
            ...params,
            sort
        }
    }
    return get(`${SEARCH_BASE_PATH}/search-products`, {params});
}

export const searchProductsRus = (keywords, pagination, sort) => {
    let params = {
        keywords,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    /* if(sort) {
        params = {
            ...params,
            sort
        }
    } */
    return get(`${SEARCH_BASE_PATH_RUS}/search-products`, {params});
}

export const searchFactories = (keywords, originalKeywords, pagination, sort) => {
    let params = {
        keywords,
        originalKeywords,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    if(sort) {
        params = {
            ...params,
            sort
        }
    }
    return get(`${SEARCH_BASE_PATH}/search-factories`, {params});
}

export const product1688 = (productId) => {
    return get(`${SEARCH_BASE_PATH}/products/${productId}`);
}

export const productRus = (productId) => {
    //console.log(SEARCH_BASE_PATH_RUS)
    return get(`${SEARCH_BASE_PATH_RUS}/products/${productId}`);
}

export const qa = (domain) => {
    let params = {
        domain
    };
    return get(`${SEARCH_BASE_PATH_RUS}/qa`, {params});
}







