import {useDispatch, useSelector} from "react-redux";
import {List} from "antd";

const DomainQAContainer = ({domain = {}}) => {
    const domainQa = useSelector(state => state.product1688.qa)

    const {qa=[]} = domainQa;


    return (
        
        <List
            size="large"
            header={<div>Ответы с сайта {domain}</div>}
            bordered
            dataSource={qa}
            renderItem={(item) => 
            <List.Item>
                <List.Item.Meta title={item.q} description={item.a}/>
            </List.Item>}
        />
    );
}

export default DomainQAContainer;