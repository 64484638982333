import React from 'react';
import {Outlet} from "react-router-dom";
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;



const PageRUS = () => {

    return(

        <Outlet />

    );
}

export default PageRUS;
