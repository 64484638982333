import React, {useState} from 'react'
import {Button, Flex, Input, Radio} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {searchFactoriesAsync, searchProductsAsync, setSearchType} from "./search1688Slice";
import {FactoriesSortTypes, ProductsSortTypes, SearchTypes} from "../../../constants/enums";
const { Search } = Input;

const SearchController = () => {
    const dispatch = useDispatch();

    const productsResult = useSelector(state => state.search1688.productsResult)
    const [productKeywords, setProductKeywords] = useState(productsResult.keywords);
    const [originalProductKeywords, setOriginalProductKeywords] = useState(productsResult.originalKeywords);
    const [productSort, setProductSort] = useState(productsResult.sort);

    const factoriesResult = useSelector(state => state.search1688.factoriesResult)
    const [factoryKeywords, setFactoryKeywords] = useState(factoriesResult.keywords);
    const [originalFactoryKeywords, setOriginalFactoryKeywords] = useState(factoriesResult.originalKeywords);
    const [factorySort, setFactorySort] = useState(factoriesResult.sort);

    const searchType = useSelector(state => state.search1688.searchType)

    const productSorts = () => {
        const {pagination: { total = 0, pageSize = 20, current= 1}} = productsResult;
        if(total > 0) {
            return (
                <Radio.Group
                    name="productSort"
                    size="small"
                    value={productSort}
                    onChange={(e) => {
                        setProductSort(e.target.value);
                        if(productKeywords) {
                            dispatch(searchProductsAsync({
                                keywords: productKeywords,
                                originalKeywords: originalProductKeywords,
                                pagination: undefined,
                                sort: e.target.value
                            }))
                        }
                    }}
                >
                    <Radio.Button value={ProductsSortTypes.DEFAULT}>по умолчанию</Radio.Button>
                    <Radio.Button value={ProductsSortTypes.SALES}>по популярности</Radio.Button>
                    <Radio.Button value={ProductsSortTypes.PRICE_DOWN}>по цене ум.</Radio.Button>
                    <Radio.Button value={ProductsSortTypes.PRICE_UP}>по цене возр.</Radio.Button>
                </Radio.Group>
            )
        }
    }

    const factorySorts = () => {
        return(
            <Radio.Group name="factorySort"
                         size="small"
                         value={factorySort}
                         onChange={(e) => {
                             setFactorySort(e.target.value);
                             if(factoryKeywords) {
                                 dispatch(searchFactoriesAsync({
                                     keywords: factoryKeywords,
                                     originalKeywords: originalFactoryKeywords,
                                     pagination: undefined,
                                     sort: e.target.value
                                 }))
                             }
                         }}
            >
                <Radio.Button value={FactoriesSortTypes.DEFAULT}>по умолчанию</Radio.Button>
                <Radio.Button value={FactoriesSortTypes.REPURCHASE_RATE}>по рейтингу повтр. заказов</Radio.Button>
            </Radio.Group>
        )
    }

    const sortRenderer = () => {
        switch (searchType) {
            case SearchTypes.PRODUCTS: return productSorts();
            case SearchTypes.FACTORIES: return factorySorts();
        }
    }

    const productSearchRenderer = () => {
        const {originalKeywords} = productsResult;
        return(
            <div>
                <Flex gap="small" align="center" className='search-editor-wrapper'>
                    {/*<div className='caption'>Поиск товара</div>*/}
                    
                    <Search className='search-editor-wrapper'
                        placeholder="Наберите наименование товара"
                        enterButton="Поиск"
                        size="large"
                        onSearch={(e) => {
                            dispatch(searchProductsAsync({
                                keywords: productKeywords,
                                originalKeywords: undefined,
                                pagination: undefined,
                                sort: productSort
                            }))}
                        }
                        value={productKeywords}
                        onChange={(e) => {
                            setProductKeywords(e.target.value)
                            setOriginalProductKeywords(undefined);
                        }}
                    />
                </Flex>
                <div className='original-request'><span>оригинальный запрос: </span>{originalKeywords}</div>
            </div>
        )
    }

    const factorySearchRenderer = () => {
        const {originalKeywords} = factoriesResult;
        return(
            <div>
                <Flex gap="small" align="center" className='search-editor-wrapper'>
                    {/*<div className='caption'>Поиск товара</div>*/}
                    <div className='search-editor'>
                        <Input placeholder="Наберите наименование товара"
                               value={factoryKeywords}
                               onChange={(e) => {
                                   setFactoryKeywords(e.target.value)
                                   setOriginalFactoryKeywords(undefined);
                               }}
                               onPressEnter={(e) => {
                                   dispatch(searchFactoriesAsync({
                                       keywords: factoryKeywords,
                                       originalKeywords: undefined,
                                       pagination: undefined,
                                       sort: factorySort
                                   }))
                               }}
                        />
                    </div>
                    <Button
                        type="primary"
                        onClick={() => {
                            dispatch(searchFactoriesAsync({
                                keywords: factoryKeywords,
                                originalKeywords: undefined,
                                pagination: undefined,
                                sort: factorySort
                            }))}
                        }
                    >
                        Поиск
                    </Button>
                </Flex>
                <div className='original-request'><span>оригинальный запрос: </span>{originalKeywords}</div>
            </div>

        )
    }

    const searchRenderer = () => {
        switch (searchType) {
            case SearchTypes.PRODUCTS: return  productSearchRenderer();
            case SearchTypes.FACTORIES: return factorySearchRenderer();
        }
    }

    return(
        <div className='search-controller-wrapper'>
            <div>
                <Radio.Group
                     name="searchTypeGroup"
                     size="small"
                     value={searchType}
                     onChange={(e) => dispatch(setSearchType(e.target.value))}
                >
                    <Radio.Button value={SearchTypes.PRODUCTS}>по товару</Radio.Button>
                    <Radio.Button value={SearchTypes.FACTORIES}>по фабрике</Radio.Button>
                </Radio.Group>
            </div>
            {searchRenderer()}
            <div className='search-controller-sort'>
                {sortRenderer()}
            </div>
        </div>
    )
}

export default SearchController;
