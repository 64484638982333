import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Store from './store'
import {Provider} from "react-redux";

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <App />
    </Provider>
);

reportWebVitals();
