import React from 'react'
import './ProductViewer.less'
import { Card, Col, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Text, } = Typography;


const ProductViewer = ({product = {}}) => {
    const {id : pageId, name, url,
        description, price, images =[], domain
    } = product;

    const { Meta } = Card;  

    return(
        <Col span={6} className='product-viewer-wrapper'>
            <Link to={`/rus/products/${pageId}?domain=${domain}`}>
                <Card
                    hoverable
                    style={{ width: 240 }}
                    className='product-viewer-card'
                    cover={<img alt={name} src={"/api/search/v1/rus/loadExtContent?path="+images[0]+"&domain="+domain} />}
                    key={pageId+"-card"}
                >
                    
                    <Space direction="horizontal" className='product-viewer-card-price'>
                        <Text>Цена</Text>
                        <Text mark>{price}</Text>
                    </Space>

                    <Meta key={pageId+"-meta"} title={name} description={domain} className='product-viewer-card-meta'/> 
        
                </Card>
            </Link>
        </Col>
    )
}

export default ProductViewer;

