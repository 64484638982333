import React,{useState} from 'react';
import  './SearchContent.less'
import SearchController from "./SearchController";
import SearchProductResultContent from "./SearchProductResultContent";
import {Layout,Flex} from "antd";
import { Header, Content } from 'antd/es/layout/layout';


const SearchRusContent = () => {
    
    return(
        <Flex vertical className='search-layout-content'>
            <SearchController />
            <SearchProductResultContent />
        </Flex>
    );
}

export default SearchRusContent;
